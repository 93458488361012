/*--------------------------------------------------------------
## Basic Color
----------------------------------------------------------------*/
$white: #fff;
$black: #161616;
$primary: black;
$secondary: rgba($primary, 0.7);
$ternary: #999696;
$border: #4D4D4D;
$gray: #181818;
$accent: #A617FA;
$success: #17b145;
$error: #d81414;
